/* Home top card */

.HomeTop {
  padding: 30px 0px;
}

.HomeCard {
  background-color: #fff;
  border-radius: 10px;
  padding: 25px 15px 25px;
  position: relative;
  box-shadow: 0px 0px 5px 0px rgb(155 153 153 / 50%);
  margin-bottom: 20px;
}

.HomeCardViewbtn {
  border: none;
  outline: 0;
  width: 80px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #066de6;
  color: #fff;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 500;
  margin-top: 15px auto 0px auto;
}

.HomeCardViewbtn:hover {
  color: #fff;
}

.HomeCardHead {
  font-size: 16px;
  margin-bottom: 10px;
  font-family: "DM Sans", sans-serif;
  font-weight: 700;
  line-height: 1.1;
  color: #364a63;
}

.HomeCard .alldata {
  width: 100%;
  height: auto;
  margin-top: 0.5rem;
}

.HomeCard .data-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.HomeCardPara {
  font-size: 2rem;
  font-weight: 500;
  color: #364a63;
  line-height: normal;
  padding: 0;
  margin: 0;
}

.HomeCard .icon_box {
  width: 100px;
  height: 60px;
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.HomeCard .icon_box i {
  font-size: 32px;
}

.HomeCard .info {
  font-size: 15px;
}

.HomeCard .change_up {
  color: #09a57b;
  font-weight: 600;
  margin-right: 3px;
}

.HomeCard .change_down {
  color: #e85347;
  font-weight: 600;
  margin-right: 3px;
}

/* .cardIconDiv {
    position: absolute;
    top: -15px;
    right: 25px;
    width: 60px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

.cardIconDiv i {
    color: #fff;
    font-size: 18px;
} */

.homecardLink {
  color: #066de6;
  display: flex;
  justify-content: end;
  font-size: 14px;
  text-decoration: none;
}

.homecardLink:hover {
  color: #066de6;
  text-decoration: none;
}

/******chartBox_area***/

.chartBox_area {
  width: 100%;

  height: 84%;
  /* padding: 10px 10px; */
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px rgb(155 153 153 / 50%);
  border-radius: 12px;
  margin-bottom: 15px;
}
.form-control {
  height: calc(1.5em + 0.75rem + 8px);
}

.piechartsBox_area {
  width: 100%;

  height: 84%;
  padding: 10px 10px;
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px rgb(155 153 153 / 50%);
  border-radius: 12px;
  margin-bottom: 14px;
}
.pieChart_area {
  width: 100%;
  height: 84%;
  padding: 10px 10px;
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px rgb(155 153 153 / 50%);
  border-radius: 12px;
  margin-bottom: 15px;
}

.chart_headline {
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  text-align: left;
  color: #364a63;
  padding: 0;
  margin-bottom: 15px;
  text-align: center;
  margin-top: 20px;
}

.recentusersTable_sec {
  width: 100%;
  height: auto;
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px rgb(155 153 153 / 50%);
  border-radius: 12px;
}

/* Responsive */

@media (max-width: 1400px) {
  .HomeCardPara {
    font-size: 1.2rem;
    font-weight: 700;
  }

  .HomeTop {
    padding: 15px 0px;
  }

  .pageHad_line {
    margin-bottom: 10px;
  }

  .HomeCard {
    padding: 10px 10px 10px;
  }

  .HomeCard .icon_box {
    width: 100px;
    height: 40px;
  }
  .HomeCard .icon_box i {
    font-size: 24px;
  }
  .HomeCardHead {
    font-size: 12px;
  }
  .HomeCard .info {
    font-size: 12px;
  }
}

@media (max-width: 1199px) {
  .cardIconDiv {
    width: 45px;
    height: 35px;
  }

  .cardIconDiv i {
    font-size: 15px;
  }
}

@media (max-width: 767px) {
  .HomeCard {
    margin: 15px 0px;
  }
  form .row{
    gap: 10px;
  }

}

@media (max-width: 480px) {
  .apexcharts-canvas {
    margin: 0 auto;
  }
}
