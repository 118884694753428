
.notification_modal,
.notification_overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 9999;
}

.notification_overlay {
    background: rgba(49, 49, 49, 0.8);
}

.notification_content {
    position: absolute;
    top: 50%;
    /* left: 85%; */
    right: 0;
    transform: translate(0%, -50%);
    line-height: 1.4;
    background: #f1f1f1;
    padding: 14px 20px;
    border-radius: 3px;
    max-width: 600px;
    height: 100vh;
    z-index: 9999;
    overflow-y: auto;
}

.notification_content::-webkit-scrollbar {
    display: none;
}


.notification_details h3 {
    font-family: 'Plus Jakarta Sans';
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    padding-bottom: 5px;

}

.notification_details p {
    font-family: 'Plus Jakarta Sans';
    font-size: 13px;
    font-weight: 300;
    line-height: 20px;
    opacity: 0.75;
    padding-bottom: 5px;
}

.notification_details h5 {
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
}

.notification_all_flx {
    display: flex;
    justify-content: start;
    gap: 20px;
    padding-top: 10px;
    padding-bottom: 5px;
}

/* .notificationmodal_main ::-webkit-scrollbar {
    display: none;
} */

.notification_all_main {
    padding: 17px;
    /* overflow: auto;
    height: 400px; */
}

p.notification_txt {
    font-family: 'Plus Jakarta Sans';
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    padding: 5px;
    padding: 0 10px;
}

.notificationmodal_main .closemark {
    position: absolute;
    z-index: 99;
    top: 10px;
    right: 33px;
    cursor: pointer;
}

/*****changepassModal_main*****/
/******************************/

.changepassModal_main {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 9999;
    background: rgba(49, 49, 49, 0.8);
}

.changepass_modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 400px;
    height: auto;
    z-index: 2;
    background: #f1f1f1;
    border-radius: 3px;
    padding: 14px 20px;
}

.changepass_content {
    width: 100%;
    max-height: 500px;
    overflow-y: auto;
}

.changepass_content::-webkit-scrollbar {
    display: none;
}

.changepass_modal .closemark {
    position: absolute;
    z-index: 5;
    top: 0px;
    right: 10px;
    cursor: pointer;
    text-decoration: none;
    box-shadow: none;
    outline: 0;
    font-size: 26px;
}

.changepass_modal .heding {
   font-size: 22px;
   font-weight: 700;
   color: #030114;
   line-height: normal;
   text-align: left;
   margin-bottom: 30px;
}

.changepass_modal .form-group {
   width: 100%;
   height: auto;
   margin-bottom: 20px;
}

.changepass_modal .pass_input {
   position: relative;
}

.changepass_modal .eye_icon {
   position: absolute;
   top: 50%;
   right: 15px;
   transform: rotateY(-50%);
   z-index: 2;
}

.changepass_modal .form-group label {
   font-size: 15px;
   font-weight: 500;
   line-height: normal;
   color: #030114;
   text-align: left;
   padding: 0;
   margin-bottom: 5px;
}

.changepass_modal .form-group input {
    width: 100%;
    height: auto;
    padding: 5px 10px;
    box-shadow: none;
    outline: 0;
}

.changepass_modal .form-group input:focus {
    box-shadow: none;
    outline: 0;
}

.changepass_modal .Submit_btn {
   width: 100%;
   height: auto;
   margin: 40px auto 0;
   display: flex;
   justify-content: center;
   align-items: center;
}

.changepass_modal .Submit_btn .btn {
   text-decoration: none;
   box-shadow: none;
   outline: 0;
   border: none;
   width: 100%;
   max-width: 150px;
   height: 40px;
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: #066DE6;
   color: #fff;
   border-radius: 4px;
   font-size: 15px;
   font-weight: 500;

}

/* edit cintact */
.editContact{
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 9999;
    background: rgba(49, 49, 49, 0.8);
  
}
.contact_modal{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 600px;
    height: auto;
    z-index: 2;
    background: #f1f1f1;
    padding: 0;

}


/*****Modal Responsive*****/
/**************************/
@media (max-width: 767px) {

    .SubscriptModal_body {
       width: 95%;
    }

    .SubscriptModal_body .content_wrap {
        width: 100%;
        height: auto;
        max-height: 380px;
        overflow-y: auto;
    }

    .paymaent_wrapbtn .Cancel_btn {
        max-width: 100%;
    }

    .paymaent_wrapbtn .pay_btn {
        max-width: 100%;
    }
    .contact_modal{
        height: 500px;
        overflow-y: auto;
        
    
    }

}