.loader-modal {
    display: block;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 10000;
    /* Sit on top */
    padding-top: 100px;
    /* Location of the box */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
}

/* Modal Content */
.loader-modal-content {
    /* background-color: #fefefe; */
    margin: auto;
    padding: 20px;
    /* border: 1px solid #888; */
    width: 100%;
    display: flex;
    justify-content: center;
}

/* The Close Button */
.loader-close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.loader-close:hover,
.loader-close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}