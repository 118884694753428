*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  width: 100%;
  max-width: 100%;
  height: 100%;
  overflow-x: hidden;
  color: #2c3659;
  background-color: #f5f6fa;
  margin: 0 auto;
}

body.drakmode {
  color: #fff;
  background-color: #000;
}

a {
  color: #2c3659;
}

body.drakmode a {
  color: #fff;
}

/* 
=============
Heading Tag
=============
*/
h1,
h2,
h3,
h4,
h5.h6,
p {
  margin: 0;
}

/* 
=============
=============
*/

.pageHad_line {
  width: 100%;
  height: auto;
  font-size: 24px;
  line-height: normal;
  text-align: left;
  font-weight: 700;
  color: #364a63;
  padding: 0;
  margin-bottom: 15px;
}

/* 
=============
Sidebar
=============
*/
.sidebarwrap {
  display: flex;
  width: 270px;
  height: 100vh;
  padding: 20px 24px;
  flex-direction: column;
  align-items: center;
  /* background-color: #10162c; */
  background-color: #fff;
  /* background-image: url(./Images/sidebar-bg.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; */
  box-shadow: 0px 0px 5px 0px #d5d3d3;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  transition: 0.6s;
}

/* .sidebarwrap::before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000c4;
  z-index: 1;
} */

.sidebarwrap .top_area {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
}

.sidebarwrap .sidebar_menu {
  position: relative;
  z-index: 2;
}

#backbtnsidebar {
  display: none;
  border: 1.5px solid #c9c6c6;
  background-color: transparent;
  text-decoration: none;
  outline: 0;
  cursor: pointer;
  box-shadow: none;
  height: 38px;
  width: 50px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin-right: 10px;
  color: #535252;
}

.logo_area {
  width: 100%;
  max-width: 262px;
  min-width: 262px;
  height: 70px;
  border-radius: 10px;
  /* border: 1.5px solid #F5F5F5; */
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo_area .dropdown {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.logo_area .logo {
  width: 200px;
  height: auto;
  overflow: hidden;
  margin: 0 auto;
}

.logo_area .logo img {
  width: 100%;
  height: 100%;
}

.logo_area .dropdown a {
  text-decoration: none;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  align-items: center;
  padding: 10px 20px;
}

.logo_area .dropdown .dropdown-item:hover {
  background-color: #e6e6e6;
}

.logo_area .dropdown .dropdown-menu a {
  font-size: 14px;
  font-weight: 500;
}

.logo_area .dropdown-toggle::after {
  display: inline-block;
  margin-left: 15px;
  vertical-align: 0.255em;
  content: "\f078";
  border: none;
  font-family: "Font Awesome 5 Free";
}

.sidebartagheding {
  font-size: 12px;
  font-weight: 600;
  margin-left: 4px;
  margin-bottom: 5px;
  margin-top: 10px;
  padding: 0px 10px;
  border-bottom: 1px solid #efd203;
  padding-bottom: 5px;
  width: 100%;
  position: relative;
}

.sidebartagheding::after {
  position: absolute;
  content: " ";
  bottom: -2px;
  left: 0;
  width: 20px;
  height: 4px;
  background-color: #efd203;
}

.sidebar_menu {
  width: 100%;
  /* max-height: 575px; */
  max-height: 85vh;
  overflow-y: auto;
}

.sidebar_menu::-webkit-scrollbar {
  display: none;
}

.sidebar_menu .Menu_Nav {
  width: 100%;
  height: auto;
  padding: 15px 0 23px;
}

.sidebar_menu .Menu_Nav .menuline {
  width: 100%;
  height: auto;
}

.sidebar_menu .nav a {
  list-style: none;
  text-decoration: none;
  box-shadow: none;
  outline: 0;
  width: 100%;
  height: 35px;
  /* margin: 5px 0; */
  display: flex;
  align-items: center;
  padding: 5px 14px;
  font-family: Plus Jakarta Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #535252;
  opacity: 0.75;
  transition: 0.6s;
  cursor: pointer;
}

.sidebar_menu .nav a:hover {
  color: #6a3fcc;
  font-weight: 700;
  font-size: 14px;
}

.sidebar_menu .nav a.active {
  color: #6a3fcc;
  font-weight: 700;
  font-size: 14px;
}

.sidebar_menu .nav a i {
  margin-right: 14px;
  font-size: 14px;
}

.sidebar_menu .Menu_Nav .Submenu {
  width: 100%;
  height: auto;
  padding-left: 30px;
  list-style: none;
}

.sidebar_menu .Menu_Nav .Submenu a {
  text-decoration: none;
  width: 100%;
  height: 20px;
  margin: 10px 0;
  display: flex;
  align-items: center;
  padding: 0;
  font-family: Plus Jakarta Sans;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #535252;
  opacity: 0.75;
  transition: 0.6s;
  cursor: pointer;
}

.sidebar_menu .Menu_Nav .Submenu a:hover {
  color: #6a3fcc;
  font-weight: 600;
  font-size: 12px;
}

.sidebar_menu .Menu_Nav .Submenu a.active {
  color: #6a3fcc;
  font-weight: 600;
  font-size: 12px;
}

.sidebar_menu .Menu_Nav .Submenu a i {
  font-size: 10px;
}

/***/

/* 
=============
Header
=============
*/
.mainheader_sec {
  width: calc(100% - 270px);
  margin-left: auto;
  height: 70px;
  padding: 5px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  box-shadow: 3px 0px 5px 0px #d5d3d3;
}

.responsive_menu {
  display: none;
  text-decoration: none;
  outline: 0;
  box-shadow: none;
  border: none;
  background-color: transparent;
  font-size: 30px;
  cursor: pointer;
  padding: 0;
  margin: 0 20px 0 0;
  border: none;
}

body.drakmode .responsive_menu {
  color: #fff;
}

.Search_box {
  display: block;
}

.Search_box .form-inline {
  position: relative;
  width: 333px;
  height: 40px;
  border-radius: 10px;
}

.Search_box .form-inline input {
  width: 100%;
  height: 100%;
  box-shadow: none;
  outline: 0;
  border: none;
  padding: 2px 10px 2px 40px;
  background: #eff2f3;
}

.Search_box .Search_icon {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translate(-50%, -50%);
  z-index: 2;
  border: none;
  box-shadow: none;
  outline: 0;
  text-decoration: none;
  padding: 0;
  margin: 0;
}

.responsSearch_btn {
  background: #eff2f3;
  text-decoration: none;
  border: none;
  outline: 0;
  box-shadow: none;
  border-radius: 4px;
  padding: 6px 12px;
  margin-right: auto;
  display: none;
}

.responsiveSearch_box .form-inline {
  position: fixed;
  width: 95%;
  height: 48px;
  border-radius: 10px;
  z-index: 9;
  top: 90px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0px 0px 5px 0px #958f8f80;
  overflow: hidden;
}

.responsiveSearch_box .form-inline input {
  width: 100%;
  height: 100%;
  box-shadow: none;
  outline: 0;
  border: none;
  padding: 2px 50px 2px 10px;
  /* background: #EFF2F3; */
  background: #fff;
}

.responsiveSearch_box .Search_icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(-50%, -50%);
  z-index: 2;
  border: none;
  box-shadow: none;
  outline: 0;
  text-decoration: none;
  padding: 0;
  margin: 0;
  background: transparent;
}

.mainheader_sec .rightcontent {
  display: flex;
  align-items: center;
  justify-content: end;
  width: 50%;
}

.mainheader_sec .actionBtn_wrap {
  display: flex;
  align-items: center;
}

.Createpost_btn .btn {
  border-radius: 10px;
  background: #2c3659;
  display: flex;
  width: 151px;
  height: 46px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.Createpost_btn .btn i {
  margin-right: 10px;
}

.notification_btn {
  position: relative;
}

.notification_btn .active {
  position: absolute;
  top: -5px;
  right: 25px;
  z-index: 1;
  height: 20px;
}

.notification_btn .active i {
  font-size: 8px;
  color: rgb(180, 2, 2);
}

.notification_btn .btn {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 5px;
  margin-right: 20px;
}

.notification_btn .btn i {
  font-size: 20px;
}

.Accountdetails {
  display: flex;
  align-items: center;
  width: 234px;
  position: relative;
}

.Accountdetails .profile_pic {
  width: 42px;
  min-width: 42px;
  height: 42px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 10px;
}

.Accountdetails .profile_pic img {
  width: 100%;
  height: 100%;
}

.Accountdetails .namearea .dropdown a {
  text-decoration: none;
  display: block;
  position: relative;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}

.Accountdetails .namearea .dropdown a strong {
  display: block;
  font-size: 14px;
  font-weight: 700;
}

.Accountdetails .namearea .dropdown a span {
  display: block;
  opacity: 0.75;
  font-size: 10px;
  font-weight: 600;
}

.Accountdetails .namearea .dropdown-toggle::after {
  display: inline-block;
  margin-left: 15px;
  vertical-align: 0.255em;
  content: "\f078";
  border: none;
  font-family: "Font Awesome 5 Free";
  position: absolute;
  top: 50%;
  right: -25px;
  transform: translateY(-50%);
  font-size: 12px;
}

.Accountdetails .namearea .dropdown-menu {
  box-shadow: 0px 0px 5px 0px #ccc;
  top: 10px !important;
  left: auto !important;
  right: -30px;
}

.Accountdetails .namearea .dropdown-menu a {
  color: #2c3659;
  font-weight: 600;
  font-size: 14px;
}

.Accountdetails .namearea .dropdown-menu a i {
  opacity: 0.7;
}

/* edit profile page */

.editProfile {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 9999;
  background: rgba(49, 49, 49, 0.8);
}

.editprofileInner {
  background-color: #fff;
  padding: 25px 20px;
  border-radius: 10px;
  width: 100%;
  max-width: 45%;
  /* margin: 0px auto; */
  top: 50%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;

  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  height: auto;
  z-index: 2;
  background: #f1f1f1;
  border-radius: 3px;
  padding: 14px 20px; */
}

.editprofileTop {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}
.inputpen {
  position: absolute;
  top: 16%;
  left: 84%;
  z-index: 999;
}
.avatar-edit {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9;
  opacity: 0;
}
.avatar-preview {
  width: 115px;
  height: 112px;
  position: relative;
}
.avatar-preview img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.pensquare i {
  font-size: 14px;
  color: #000;
}
.avatar-upload {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .avatar-preview {
  width: 122px;
  height: 131px;
}
.avatar-preview img {
  width: 100%;
  height: 100%;
} */
/* .formheight {
  height: 400px;
  overflow-y: auto;
} */
.editprofileTop h3 {
  color: #000;
  font-size: 25px;
  font-weight: 600;
  text-align: center;
}

.editprofileInner label {
  display: block;
  width: 100%;
  font-size: 16px;
  color: #000;
  font-weight: 500;
}

.profileinputDiv {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  /* border-radius: 10px; */
  border: 1px solid grey;
  padding-right: 5px;
  background: #e9edf1;
}

.profileinputDiv i {
  color: #00114e;
  font-size: 16px;
  cursor: pointer;
}

.editprofileInner input {
  display: block;
  width: 100%;
  height: 40px;
  background: transparent;
  /* border-radius: 10px;
  border: 1px solid grey; */
  border: none;
  outline: 0;
  padding: 5px 10px;
  font-size: 15px;
}

.editsubmitbtn {
  background-color: #213363;
  color: #fff;
  border: none;
  font-size: 16px;
  padding: 5px 15px;
  border-radius: 8px;
}

#editsubmitbtndiv {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

/* 
=============
Dashboard
=============
*/

.main_wrap {
  width: calc(100% - 270px);
  height: 100vh;
  margin-left: auto;
  margin-top: 70px;
  padding: 20px 15px 60px;
  /* background-image: url(../src/Images/wedding-bg.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: -2; */
}

/* .main_wrap::before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000077;
  z-index: -1;
} */

/*----=== login  ===----*/

.login-main-div {
  height: 100vh;
}

.pass_input {
  position: relative;
}

.eye_icon {
  position: absolute;
  top: 50%;
  right: 5%;
  transform: translateY(-50%);
}

/******contauctUs_page*****/

.contauctUs_page {
  width: 100%;
  height: auto;
  padding: 20px 0;
}

.aboutUs_page {
  width: 100%;
  height: auto;
  padding: 20px 0;
}

.logo-sec {
  width: 100%;
  height: auto;
  padding: 20px 0;
}

.Pageheadline {
  font-size: 22px;
  font-weight: 600;
  line-height: normal;
  text-align: left;
  color: #10162c;
  padding: 0;
  margin-bottom: 20px;
  text-decoration: underline;
}

.Pageheadline2 {
  font-size: 22px;
  font-weight: 600;
  line-height: normal;
  text-align: left;
  color: #10162c;
  padding: 0;
  margin-bottom: 0;
  text-decoration: underline;
}

.contauctUs_page form {
  width: 100%;
  height: auto;
  padding: 15px;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px #8a8989;
}

.aboutUs_page form {
  width: 100%;
  height: auto;
  padding: 15px;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px #8a8989;
}

.logo-sec form {
  width: 100%;
  height: auto;
  padding: 15px;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px #8a8989;
}

.contauctUs_page .form-group {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.aboutUs_page .form-group {
  width: 100%;
  height: auto;
  margin-bottom: 0;
}

.logo-sec .form-group {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.contauctUs_page .form-group label {
  width: 100%;
  font-size: 15px;
  line-height: normal;
  text-align: left;
  font-weight: 600;
  color: #10162c;
  padding: 0;
  margin-bottom: 5px;
}

.aboutUs_page .form-group label {
  width: 100%;
  font-size: 15px;
  line-height: normal;
  text-align: left;
  font-weight: 600;
  color: #10162c;
  padding: 0;
  margin-bottom: 5px;
}

.logo-sec .form-group label {
  width: 100%;
  font-size: 15px;
  line-height: normal;
  text-align: left;
  font-weight: 600;
  color: #10162c;
  padding: 0;
  margin-bottom: 5px;
}

.contauctUs_page .form-group input {
  width: 100%;
  height: 40px;
  padding: 5px 10px;
  margin: 0;
  color: #10162c;
  opacity: 0.7;
  box-shadow: none;
  outline: 0;
}

.aboutUs_page .form-group input {
  width: 100%;
  height: 40px;
  padding: 5px 10px;
  margin: 0;
  color: #10162c;
  opacity: 0.7;
  box-shadow: none;
  outline: 0;
}

.logo-sec .form-group input {
  width: 100%;
  height: 40px;
  padding: 5px 10px;
  margin: 0;
  color: #10162c;
  opacity: 0.7;
  box-shadow: none;
  outline: 0;
}

.dataTableParDiv {
  width: 100%;
  height: auto;
  padding: 15px;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px #8a8989;
}

.subAllBtn {
  margin-top: 15px;
  text-align: center;
}

/**manageContact_sec**/
.manageContact_sec {
  width: 100%;
  height: auto;
  padding: 30px 0 20px;
}
/* add height page*/
.height_inpt {
  position: relative;
}
.height_unit {
  position: absolute;
  top: 78%;
  right: 6%;
  transform: translate(-50%, -50%);
}
.incomeRange input {
  width: 100%;
  max-width: 85%;
}
.incomeSelect select {
  width: 100%;
  max-width: 80%;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.incomeSelectCurrency select {
  width: 100%;
  max-width: 93%;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.ZodiacDiv select {
  padding: 8px;
  margin-top: -6px;
  width: 100%;
  max-width: 93%;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.table_page_select {
  padding: 12px;
  width: 100%;
  max-width: 93%;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.table_btn {
  height: 35px;
  width: 70px;
  margin-top: 33px;
}
.searchDiv {
  width: 100%;
  height: auto;
  padding: 10px;
  margin-bottom: 7px;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px #8a8989;
}

/* ================*/
/* add employee */
/* ================*/
.subAdmin_form {
  padding: 20px 50px;
}
.myAll_checkbox {
  background: #fff;
  padding: 15px;
  height: 300px;
  overflow-y: auto;
  width: 48%;
}
.myAll_checkbox > div {
  margin-bottom: 20px;
}
.myAll_checkbox > div > div {
  margin-bottom: 5px;
  gap: 5px;
  align-items: center;
}
.myAll_checkbox > div > div > div {
  margin-bottom: 5px;
  gap: 5px;
  padding-left: 15px;
  /* onClick={(e)=>{setEditdlt(e.target.checked)}} */
}
.myAll_checkbox > div > div label {
  margin-bottom: 0;
  text-transform: capitalize;
}
/* 
=============
account purchase details view
=============
*/
.singlemain {
  display: flex;
  justify-content: space-between;
  height: auto;
  width: 100%;
  padding: 10px;
  border: 2px solid #dbc3c3;
}
.singlemain_left,
.singlemain_right {
  width: 49%;
  box-shadow: 0 0 2px 2px;
}

.singleViewdisapprovedBtn {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
  border-radius: 5px;
  padding: 2px;
}
.singleViewApprovebtn {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  border-radius: 5px;
  padding: 2px;
  cursor: default;
}
.activebtn {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  border-radius: 5px;
  padding: 2px;
}
.inactivebtn {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
  border-radius: 5px;
  padding: 2px;
}
.dataTable .table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
  font-size: 14px;
  white-space: nowrap;
}

/*
CK EDITOR
*/
.ck-editor__main .ck-content {
  height: 26vh;
}
.dataTables_paginate {
  display: flex;
  justify-content: end;
}
/*
SINGLE USER REPORT VIEW
*/
.Account_main {
  width: 100%;
  background-color: #f1f1f1;
  height: auto;
  padding: 10px;
  display: flex;
  justify-content: space-between;
}
.Account_main .purchase,
.sold {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  background-color: #e3dfdf;
  border-radius: 10px;
  padding: 0 5px;
  margin-top: 10px;
}
.purchae_ac_img {
  display: flex;
  height: 100px;
  width: 100px;
  border-radius: 30%;
}
.purchae_ac_img img {
  height: 100%;
  width: 100%;
  border-radius: 20%;
}
.information {
  margin-left: 15px;
  font-size: 13px;
}
/* ========
table 
===========*/
.Search_main_div {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.searchById input {
  outline: none;
  padding: 6px;
  margin-left: 5px;
  width: 370px;
}
.searchById_btn {
  height: 40px;
  width: 72px;
  border: none;
  border-radius: 5px;
  background-color: #3083e9;
  color: white;
  margin-left: 10px;
}

.userTbl {
  width: 100%;
  margin-top: 20px;
}

.userTbl table {
  width: 100%;
  overflow-x: auto;
}

.userTbl thead {
  border-top: 1px solid grey;
}

.userTbl tbody tr:nth-child(odd) {
  background-color: rgb(243, 243, 243);
}

.userTbl tbody tr {
  border-top: 1px solid grey;
}

.userTbl th {
  width: auto;
  white-space: nowrap;
  padding: 4px 8px;
  font-size: 14px;
  /* text-align: center; */
}

.userTbl td {
  width: auto;
  white-space: nowrap;
  padding: 4px 8px;
  font-size: 12px;
  /* text-align: center; */
}

/* .pagination */
.Number_div {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.pagination_btn {
  height: 35px;
  width: auto;
  padding: 0px 5px;
  color: white;
  outline: none;
  background-color: #0062cc;
  border-radius: 5px;
}
.pagination_mid_num {
  width: 250px;
  display: flex;
}

.pagination_mid_btn {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}
.show_entries select {
  height: 25px;
  width: 150px;
  font-size: 14px;
}
.manage_search_div {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 2px 5px;
}
.manage_search_div input {
  padding: 5px;
  outline: none;
  border: 1px solid rgb(180, 180, 180);
  width: 389px;
  margin-right: 10px;
  border-radius: 7px;
}

/* table pagination css */
.page-link {
  cursor: pointer;
}

/* view single user view report */
.nodata {
  color: red;
  font-size: 15px;
  height: 50px;
  text-align: center;
  margin-top: 10px;
}
.label {
  text-align: center;
  padding-top: 5px;
  width: 96%;
  background: rgba(210, 127, 127, 0.4);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 7px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}
.single_prifile_acc {
  margin-bottom: 20px;
  display: flex;
  justify-content: left;
  align-items: center;
  height: 100px;
  width: 98%;
  /* background-color: #e9e9e9; */

  background: rgba(228, 183, 183, 0.45);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.single_prifile_acc img {
  margin-left: 10px;
}

.image_item {
  position: relative;
  width: 244px;
  height: 300px;
  margin-bottom: 10px;
}
.image_item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}
.image_delete {
  position: absolute;
  top: 0;
  right: 0;
}
.prflpicimgdiv {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 1%;
}

#prnt_btn {
  height: 32px;
  width: 70px;
  background-color: #0062cc;
  border-radius: 5px;
  color: #fff;
  outline: none;
  border: none;
  margin-right: 15px;
}

/*=========
image modal
=========*/

/* The Modal (background) */
.img_modal {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 9999;
  background: rgba(49, 49, 49, 0.8);
}

/* Modal Content (image) */
.modal-content {
  /* background-color: #fff; */
  /* padding: 25px 20px; */
  border-radius: 10px;
  height: 500px;
  width: 100%;
  max-width: 55%;
  /* margin: 0px auto; */
  top: 50%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
}

/* Caption of Modal Image */
#caption {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  height: 150px;
}

/* .modal-content,
#caption {
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
  from {
    opacity: 0;
    -webkit-transform: scale(0.1);
    transform-origin: center center;
  }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform-origin: center center;
  }
}

@keyframes zoom {
  from {
    opacity: 0;
    transform: scale(0.1);
    transform-origin: center center;
  }
  to {
    opacity: 1;
    transform: scale(1);
    transform-origin: center center;
  }
} */

/* The Close Button */
.close {
  position: relative;
  top: 43px;
  right: 277px;
  color: #ff5454;
  font-size: 38px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #ff0000;
  text-decoration: none;
  cursor: pointer;
}

/* 
=============
Responsive
=============
*/

@media (max-width: 1400px) {
  .sidebarwrap {
    width: 250px;
  }

  .mainheader_sec {
    width: calc(100% - 250px);
  }

  .main_wrap {
    width: calc(100% - 250px);
  }

  .logo_area {
    min-width: 170px;
    height: 60px;
  }

  .Accountdetails {
    width: 195px;
  }
}

@media (max-width: 1199px) {
  .mainheader_sec .rightcontent {
    width: 55%;
  }

  .Search_box .form-inline {
    width: 280px;
  }

  .activityprogress .boxwrap {
    height: 120px;
  }

  .Accountdetails .namearea .dropdown-toggle::after {
    right: 10px;
    top: 32%;
  }
}

@media (max-width: 991px) {
  .sidebarwrap {
    width: 280px;
    /* position: absolute; */
    top: 0;
    left: -300px;
    height: 100%;
  }

  .sidebarwrap.show {
    left: 0px;
  }

  #backbtnsidebar {
    display: flex;
  }

  .mainheader_sec {
    width: 100%;
  }

  .main_wrap {
    width: 100%;
  }

  .responsive_menu {
    display: block;
  }
}

@media (max-width: 767px) {
  .Accountdetails .namearea .dropdown a strong {
    display: none;
  }

  .Accountdetails .namearea .dropdown a span {
    display: none;
  }

  .Accountdetails {
    width: 42px;
    position: relative;
  }

  .Accountdetails .profile_pic {
    margin-right: 0;
  }

  .Accountdetails .namearea .dropdown-toggle::after {
    right: 0;
    opacity: 0;
  }

  .mainheader_sec .rightcontent {
    width: 38%;
  }

  .Createpost_btn .btn {
    height: 36px;
    font-size: 10px;
    width: 100px;
    margin-right: 10px;
  }

  .Search_box {
    display: none;
  }

  .responsSearch_btn {
    display: block;
  }

  .mainheader_sec {
    height: 80px;
    padding: 10px 20px;
  }

  .main_wrap {
    margin-top: 70px;
  }
}

@media (max-width: 600px) {
  .mainheader_sec .rightcontent {
    width: 50%;
  }
}

@media (max-width: 480px) {
  .mainheader_sec .rightcontent {
    width: 65%;
  }

  .main_wrap {
    padding: 0px 10px 30px;
  }

  .activityprogress .boxwrap {
    height: 75px;
  }

  .notification_btn .btn {
    width: 30px;
    height: 30px;
  }

  .notification_btn .btn i {
    font-size: 16px;
  }

  .notification_btn .active {
    top: -6px;
    right: 25px;
  }

  .Accountdetails {
    width: 32px;
  }

  .logo_area {
    height: 40px;
    min-width: 140px;
  }

  .sidebarwrap {
    width: 250px;
  }
}

@media print {
  .mainheader_sec {
    position: absolute;
  }
  .form-group label {
    margin-top: 5px !important;
  }
}
